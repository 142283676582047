/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.scroll-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: #e42f17;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
}

.scroll-to-top-button.visible {
  display: block;
}

.scroll-to-top-button:hover {
  background-color: #e42f17;
}


/* CSS Styling */
.StepCount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.StepCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0; /* Inactive circle color */
  margin: 0 10px;
  font-size: 14px;
  color: #666;
}

.StepCircle.active {
  background-color: #42a5f5; /* Active circle color */
  color: #fff;
}

.input-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.input-group.no-margin-bottom {
  margin-bottom: 0;
}


.input-group input {
  width: 320px;
  height: 44px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  outline: none;
  background-color: transparent;
  color: #4b5563;
  
}

.input-group label {
  position: absolute;
  top: 50%;
  left: 10px;
  padding-left: 2px;
  padding-right:2px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  pointer-events: none;
  color: #999;
  
  font-size: 16px;
  z-index: 1;
  background-color: white;
}

.input-group.active label {
  top: 0;
  font-size: 12px;
  color: #333;
  
}

.input-group input:focus {
  border-color: #999;
  background-color: white;
}

.input-group input:focus + label {
  top: 0;
  font-size: 12px;
  color: #333;
  
  background-color:white;
}

.input-group input:focus::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #999;
  border-radius: 4px;
  pointer-events: none;
  z-index: -1;
  background-color: white;
}
.input-group input:focus::placeholder {
  color: transparent;
}


.search-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
 
}


.search-bar-title {
  margin-bottom: 20px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .search-bar-title {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .search-bar-title {
    font-size: 35px;
  }
}

.search-bar-input {
 
  display: flex;
}

.search-bar-input input {
 
  width: 400px;
  height: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  margin-right: 20px;
}
@media (max-width: 768px) {
  .search-bar-input input {
    width: 200px;
  }
}



.search-bar-button {
  

  background-color: #e42f17;
  color: #fff;
  border: none;
  border-radius: 9999px; 
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.search-bar-button:hover {
  background-color: #e5250e;
}

.search-bar-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(228, 47, 23, 0.4);
}

.blue-on-hover:hover {
  color: blue;
}

/* .search-bar-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar-input input {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  
}

.search-bar-button {
  width: 100%;
  max-width: 400px; 
 
} */



